import React, { useContext, useEffect, useState, useCallback } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import { SketchPicker } from 'react-color';
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Select,
  /* DatePicker, */ Button,
  Form,
  Row,
  Col,
  TreeSelect,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import { UserContext } from '../../App';
import { Tree, TreeProps } from 'antd';
import { GalleryModal } from './';
import { UploadOutlined } from '@ant-design/icons';
import UploadDocument from './UploadDocument';
import axios from 'axios';

const { Option } = Select;
const { TreeNode } = Tree;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 10;

const configJodit = {
  style: {
    font: '14px Poppins',
  },
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
    'image',
  ],
};

const formInit = {
  _id: null,
  naziv: {},
  googleDescription: {},
  keywords: {},
  youtubeLink: '',
  content: {},
  code: '',
  price: 0,
  naakciji: true,
  Popust: 0,
  CenaPopust: 0,
  razlikaUCeni: 0,
  fullUrl: '',
  featuredAd: false,
  uvoznik: '',
  featuredAdUntil: '',
  status: 'AKTIVAN',
  category: '',
  categoryPath: '',
  attributes: [],
  featureImage: undefined,
  gallery: [],
  declaration: undefined,
  attachments: [],
};

const TreeComponent = ({ hierarchy, handleOnSelect, category, fullCategoryList, getCategoryItem }) => {
  // const [value, setValue] = useState(category);
  // const onSelect = (newValue) => {
  //   setValue(newValue);
  //   console.log(newValue);
  // };

  // const renderTreeNodes = (podaci) =>
  //   podaci?.map((item) => {
  //     if (item.children !== 'undefined') {
  //       return (
  //         <TreeNode key={item.ID} value={item.ID} title={item.naziv} dataRef={item.naziv}>
  //           {renderTreeNodes(item.children)}
  //         </TreeNode>
  //       );
  //     }
  //     return <TreeNode key={item.ID} {...item} />;
  // });

  useEffect(() => {
    const categoryItem =
      fullCategoryList &&
      fullCategoryList.data &&
      fullCategoryList.data.length > 0 &&
      fullCategoryList.data.find((item) => +item.ID === +category);

    if (categoryItem) {
      getCategoryItem(categoryItem);
    }
  }, [fullCategoryList, category]);

  return (
    <TreeSelect
      style={{
        width: '100%',
      }}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      value={category}
      placeholder='Odaberite kategoriju'
      allowClear
      treeDefaultExpandAll
      // treeExpandAction={true}
      onSelect={handleOnSelect}
    >
      {/* {renderTreeNodes(hierarchy)} */}
    </TreeSelect>
  );
};

const DataForm = ({
  isNew,
  data,
  categories,
  fullCategoryList,
  onSubmit,
  SERVER_URL,
  token,
  declarations,
  allData,
  props,
  uploadData,
}) => {
  const [form] = Form.useForm();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  const [globalAttributes, fetchGlobalAttributes] = useAxios('', [], token, 'get');
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [treeData, setTreeData] = useState([]);
  const currentuser = useContext(UserContext);
  const [reqCheck, setReqCheck] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  console.log('globalAttributes', globalAttributes);

  console.log('dataaaaa', { data });
  // let initVal = { ...initialValues, ...data, test: data.naakciji === 'N' ? false : true }; // naakciji: data.naakciji === 'D' ? false : true
  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(initialValues.gallery?.length > 0 ? initialValues.gallery : []);
  const [attachments, setAttachments] = useState();

  useEffect(() => {
    if (data && data?.attachments) {
      setAttachments(data?.attachments);
    }
  }, [data?.attachments]);

  // Select CATEGORY
  const [category, setCategory] = useState();
  const handleOnSelect = (data) => {
    setCategory(data);
  };

  useEffect(() => {
    if (data && data.IDgrupe) {
      setCategory(data.IDgrupe);
    }
  }, [data, setCategory]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      let data = [];

      data = getTreeFromFlatData({
        flatData: categories.map((node) => ({ ...node, title: node.naziv })),
        getKey: (node) => node.ID, // resolve a node's key
        getParentKey: (node) => node.nivo, // resolve a node's parent's key
        rootKey: 0,
        // rootKey: 0, // The value of the parent key when there is no parent (i.e., at root level)
      });
      setTreeData(data);
    }
  }, [categories]);

  const [categoryItem, setCategoryItem] = useState({});
  const getCategoryItem = (item) => {
    setCategoryItem(item);
  };

  // Select ATTRIBUTES

  useEffect(() => {
    fetchGlobalAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":true}`, []);

    // if (data?.nazivgrupe) {
    getAttributes();
    // }

    if (data) {
      if (data.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: moment(data.featuredAdUntil) });
      if (data.category) {
        const categoryPath = getCategoryPath(data.category, categories);
        fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
      }
      if (data.attributes && data.attributes.length > 0) {
        data.attributes.forEach((d) => {
          if (d && d.dateValue) {
            d.dateValue = moment(d.dateValue);
          }
        });
      }
    }
  }, [data, categories, form, SERVER_URL, fetchAttributes, fetchGlobalAttributes]);

  const getAttributes = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/attributes`,
        {
          params: {
            categories: [data?.fullCategoryName],
          },
        },
        {
          withCredentials: false,
          paramsSerializer: (params) => {
            return stringify(params);
          },
        },
      );

      if (response) {
        console.log('responseresponse', response);
        setProductAttributes(response.data.items);
      }
    } catch (error) {
      console.log(error);
      setProductAttributes([]);
    }
  };

  console.log('productAttributes', productAttributes);

  useEffect(() => {
    if (globalAttributes.data.items && attributes.data) {
      setCategoryAttributes([...globalAttributes.data.items, ...attributes.data]);
    }
  }, [attributes, globalAttributes]);

  // const onChangeAttribute = (index, attrId, name, value) => {
  //   console.log('aaaaaaaaaa', value);
  //   let prevAttributes = form.getFieldValue('attributes');
  //   const attr = {
  //     attributeId: attrId,
  //     [name]: name === 'values' ? (!Array.isArray(value) ? [value] : value) : value,
  //   };
  //   console.log('att', attr);
  //   prevAttributes[index] = attr;
  //   form.setFieldsValue({ attributes: prevAttributes });
  // };

  const onChangeAttribute = (index, attrId, name, value) => {

    console.log('aaaaa', value);
    let prevAttributes = form.getFieldValue('attributes');
    const attr = {
      attributeId: attrId,
      [name]: name === 'values' ? (!Array.isArray(value) ? [value] : value) : value,
    };
    prevAttributes[index] = attr;
    form.setFieldsValue({ attributes: prevAttributes });
  };

  const onFinish = async (values, isNew, reqCheck) => {
    if (!isNew) {
      values._id = data._id;
    }

    const goodAttachments = attachments?.filter((item) => item.status === 'done');

    const finalValues = { ...values, attachments: goodAttachments };

    console.log({ values, finalValues });

    onSubmit(finalValues, isNew, categoryItem, reqCheck);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const isChecked = data.naakciji === 'N' ? false : true;
      setIsCheck(false);
    }
  }, [data]);

  const [isCheck, setIsCheck] = useState(false);
  const onChangeCheck = (event) => {
    const { checked } = event.target;

    if (checked) {
      setIsCheck(true);
      form.setFieldsValue({
        naakciji: 'D',
      });
    } else {
      setIsCheck(false);
      form.setFieldsValue({
        naakciji: 'N',
      });
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const isChecked = data && data.recommended;
      setReqCheck(isChecked);
    }
  }, [data]);

  const onReqChangeCheck = (event) => {
    const { checked } = event.target;

    if (checked) {
      setReqCheck(true);
    } else {
      setReqCheck(false);
    }
  };

  const editHandlerDocument = () => {
    console.log('test');
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data, reqCheck)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Naziv proizvoda'
                  rules={[
                    {
                      required: true,
                      message: 'Unesite naslov!',
                    },
                  ]}
                  name={'naziv'}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item label='Google opis' name={'googleDescription'}>
                  <Input />
                </Form.Item>

                <Form.Item label='Google ključne reči' name={'keywords'}>
                  <Input />
                </Form.Item>

                <Form.Item label='YouTube video' name={'youtubeLink'}>
                  <Input />
                </Form.Item>

                <Form.Item label='Sadržaj' name={'opis'}>
                  <JoditEditor
                    name={`opis`}
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Form.Item label='Specifikacija' name={'specifikacija'}>
                  <JoditEditor
                    name={`specifikacija`}
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Divider type='horizontal' />

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8} style={{ maxWidth: '500px' }}>
                    <Form.Item label='Šifra proizvoda' name='sifra' style={{ maxWidth: '300px' }}>
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8} style={{ maxWidth: '500px' }}>
                    <Form.Item
                      label='Status'
                      name='status'
                      rules={[
                        {
                          required: true,
                          message: 'Odaberite status!',
                        },
                      ]}
                      style={{ maxWidth: '300px' }}
                    >
                      <Select>
                        {['AKTIVAN', 'NEAKTIVAN'].map((option, index) => (
                          <Select.Option key={`${option}_${index}`} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8} style={{ maxWidth: '500px' }}>
                    <Form.Item label='Cena' name='Cena' style={{ maxWidth: '300px' }}>
                      <Input type='number' disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='Na lageru' name='onStock' valuePropName='checked' style={{ maxWidth: '150px' }}>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={6} style={{ maxWidth: '150px' }}>
                    <Form.Item label='Popust %' name='dicsountNumber' style={{ width: '100px' }}>
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8} style={{ maxWidth: '150px' }}>
                    <Form.Item label='Na popustu' name='naakciji'>
                      <Checkbox checked={isCheck} onChange={onChangeCheck} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8} style={{ maxWidth: '150px' }}>
                    <Form.Item label='Popust %' name='Popust' style={{ width: '100px' }}>
                      <Input type='number' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8} style={{ maxWidth: '300px' }}>
                    <Form.Item label='Cena na popustu' name='CenaPopust' style={{ width: '250px' }}>
                      <Input type='number' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8} style={{ maxWidth: '300px' }}>
                    <Form.Item label='Razlika u ceni' name='razlikaUCeni' style={{ width: '250px' }}>
                      <Input type='number' />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            {/* CATEGORY SELECTOR */}
            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>Kategorija</h4>
                  </div>

                  <Form.Item label='Kategorija' name='category' className='panel-body'>
                    {treeData && treeData.length > 0 && (
                      <TreeComponent
                        showLine
                        hierarchy={treeData}
                        handleOnSelect={handleOnSelect}
                        category={category}
                        fullCategoryList={fullCategoryList}
                        getCategoryItem={getCategoryItem}
                      />
                    )}
                  </Form.Item>
                </div>

                {/* ATRIBUTES SELECTOR */}
                <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Atributi</h4>
                </div>

                <div className='panel-body'>
                  <Form.List name='attributes'>
                    {(fields) =>
                      categoryAttributes.length > 0 &&
                      categoryAttributes.map((attr, ind) => {
                        switch (attr.type) {
                          case 'MULTICHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'values']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  mode='multiple'
                                  onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'CHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'values', 0]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}>
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'INPUT':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'inputValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'inputValue', e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'NUMBER':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'numberValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  type='number'
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'numberValue', +e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'CHECKBOX':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'checkboxValue']}
                                // className='panel-body'
                                valuePropName='checked'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Checkbox
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'checkboxValue', e.target.checked)}
                                />
                              </Form.Item>
                            );

                          // ***************** IN PROGRESS *****************
                          // case 'DATE':
                          //   return (
                          //     <Form.Item
                          //       key={attr._id}
                          //       label={attr.name}
                          //       name={[ind, 'dateValue']}
                          //       // className='panel-body'
                          //       rules={[
                          //         { type: 'object', required: attr.isRequired, message: `Please select ${attr.name}` },
                          //       ]}
                          //       getValueProps={() => {}}
                          //       {...fields}
                          //     >
                          //       <DatePicker
                          //         style={{ width: '100%' }}
                          //         size='large'
                          //         onChange={(date) => onChangeAttribute(ind, attr._id, 'dateValue', date)}
                          //       />
                          //     </Form.Item>
                          //   );

                          default:
                            return null;
                        }
                      })
                    }
                  </Form.List>
                </div>
              </div>

                {/* <div className='panel-body'>
                  <Form.List name='attributes'>
                    {(fields) =>
                      categoryAttributes.length > 0 &&
                      categoryAttributes.map((attr, ind) => {
                        switch (attr.type) {
                          case 'MULTICHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'values']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  mode='multiple'
                                  onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'CHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'values', 0]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}>
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'INPUT':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'inputValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'inputValue', e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'NUMBER':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'numberValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  type='number'
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'numberValue', +e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'CHECKBOX':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name}
                                name={[ind, 'checkboxValue']}
                                // className='panel-body'
                                valuePropName='checked'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Checkbox
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'checkboxValue', e.target.checked)}
                                />
                              </Form.Item>
                            );

                          // ***************** IN PROGRESS *****************
                          // case 'DATE':
                          //   return (
                          //     <Form.Item
                          //       key={attr._id}
                          //       label={attr.name}
                          //       name={[ind, 'dateValue']}
                          //       // className='panel-body'
                          //       rules={[
                          //         { type: 'object', required: attr.isRequired, message: `Please select ${attr.name}` },
                          //       ]}
                          //       getValueProps={() => {}}
                          //       {...fields}
                          //     >
                          //       <DatePicker
                          //         style={{ width: '100%' }}
                          //         size='large'
                          //         onChange={(date) => onChangeAttribute(ind, attr._id, 'dateValue', date)}
                          //       />
                          //     </Form.Item>
                          //   );

                          default:
                            return null;
                        }
                      })
                    }
                  </Form.List>
                </div> */}
              </div>

              {/* PICTURE GALLERY */}
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Glavna slika</h4>
                </div>

                <Form.Item name='featureImage' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox
                      editHandler={editImageTrigger}
                      deleteHandler={deleteFeatureImageHandler}
                      image={image}
                      index={0}
                      name='featureImage'
                    />
                  </div>
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Galerija slika</h4>
                </div>

                <Form.Item name='gallery' valuePropName='image'>
                  <div className='panel-body'>
                    {currentGallery?.length > 0 && (
                      <DNDGallery
                        deleteGalleryImageHandler={deleteGalleryImageHandler}
                        editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                        form={form}
                        setGallery={setCurrentGallery}
                      />
                    )}

                    {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                  </div>
                </Form.Item>
              </div>

              {/* UPLOAD DOCUMENTS */}
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Dokumenta</h4>
                </div>

                <Form.Item name='attachments'>
                  <div className='panel-body'>
                    <UploadDocument attachments={attachments} setAttachments={setAttachments} />
                  </div>
                </Form.Item>
              </div>

              {/* RECOMMENDED PRODUCT CHECKER */}
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Preporučeni proizvod</h4>
                </div>

                <Checkbox checked={reqCheck} name='recommended' className='panel-body' onChange={onReqChangeCheck}>
                  Preporučeni proizvod
                </Checkbox>
              </div>
              <Form.Item
                label='Proizvođač'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Unesite uvoznika!',
                //   },
                // ]}
                name={'proizvodjac'}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Naziv i vrsta robe (kategorija)'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Unesite zemlju uvoza!',
                //   },
                // ]}
                name={'nazivgrupe'}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Zemlja porekla'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Unesite zemlju porekla!',
                //   },
                // ]}
                name={'zemljaporekla'}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} proizvod
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/data/'
            imageType='data'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default DataForm;
