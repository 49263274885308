import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import BadgeTable from '../../components/tables/BadgeTable';
import BadgeForm from '../../components/forms/BadgeForm';

const Badges = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  // const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');

  const [badges, fetchBadges] = useAxios('', [], currentuser.data.token, 'get');

  console.log('bedzevi badges.js', badges.data.items);

  const [edit, setEdit] = useState({ visible: false, badge: {} });

  let columnKeys = ['name', 'position', 'color'];

  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, [data]);
  }, [fetchData]);
  useEffect(() => {
    fetchBadges(`${SERVER_URL}/badges`, []);
  }, [fetchBadges]);

  console.log('products', data);

  const deleteAttributeHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/badge/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Badge is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-badges');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onSubmitBadge = async (data, isNew) => {
    console.log('dataa', data);
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/badges` : `${SERVER_URL}/badge/${edit.badge._id}`;
    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Badge ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-badges');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const editAttributeHandler = (badgeId) => {
    setEdit({
      visible: true,
      badge: badges.data.items.find((a) => a._id === badgeId) || {},
    });
  };

  console.log('edit', edit);

  let tableData = [];
  if (badges.data.items && badges.data.items.length > 0) {
    tableData = badges.data.items.map((item, index) => {
      let position;
      if (item.position === 0) {
        position = 'Gore levo';
      }
      if (item.position === 1) {
        position = 'Gore desno prvi';
      }
      if (item.position === 2) {
        position = 'Gore desno drugi';
      }
      return { ...item, position: position };
    });
  }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Button type='primary' onClick={() => editAttributeHandler(null)}>
          Dodaj bedž za akciju
        </Button>
      </div>

      <Row type='flex' gutter={8}>
        <Col xs={24} md={edit.visible ? 16 : 24}>
          <div style={{ textAlign: 'center' }}>
            {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

            {data.isLoading && data.isError && !data.data.items && data.data.items.length > 0 && <h2>Nema bedževa</h2>}

            {!data.isLoading && !data.isError && data && data.data && data.data.items && data.data.items.length > 0 && (
              <BadgeTable
                data={tableData}
                deleteHandler={deleteAttributeHandler}
                editHandler={editAttributeHandler}
                columnKeys={columnKeys}
              />
            )}
            {!data.isLoading && !data.isError && data.data.items && data.data.items.length === 0 && (
              <div className='no-data-box'>
                <h2>Nema bedževa</h2>
              </div>
            )}
          </div>
        </Col>

        {edit.visible && (
          <Col xs={24} md={8} className='add-atributes'>
            <BadgeForm onSubmit={onSubmitBadge} data={edit.badge} setEdit={setEdit} products={data} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Badges;
