import React from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table } from 'antd';

const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  userId: null,
  totalAmount: null,
  status: '',
  order: [],
  paymentMethod: '',
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = ['Na čekanju', 'Isporučeno', 'Nije isporučeno'];
  const onFinish = async (values) => {
    onSubmit(values);
  };
  const columns = [
    {
      key: '1',
      title: 'Proizvod',
      dataIndex: 'productName',
    },
    {
      key: '2',
      title: 'Kategorija',
      dataIndex: 'productCategory',
    },
    {
      key: '3',
      title: 'Puna Cena',
      dataIndex: 'productPrice',
    },
    {
      key: '4',
      title: 'Cena sa popustom',
      dataIndex: 'productDiscountPrice',
    },
    {
      key: '5',
      title: 'Količina',
      dataIndex: 'quantity',
    },
  ];
  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }

  // FORMATER CENA - NA SRPSKU VALUTU
  const formatPrice = (number) => {
    const price = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2,
      roundingIncrement: 5,
    }).format(number);
    return price.replace('€', 'RSD');
  };

  let obj = {};
  const productData = [];

  data?.order?.map((item) => {
    obj.productName = item.productName;
    obj.productCategory = item.productCategory;
    obj.productPrice = formatPrice(item.productPrice);
    obj.productDiscountPrice = formatPrice(item.productDiscountPrice);
    obj.quantity = item.quantity;
    productData.push(obj);
  });

  const fixedAmount = formatPrice(data.totalAmount);

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Informacije o narudžbi' bordered={false}>
                  <p>
                    <Text strong>Broj narudžbe: </Text>
                    {data.id ? data.id : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {fullName !== '' ? fullName : `${data.guestUserData.firstName} ${data.guestUserData.lastName}`}
                  </p>
                  <p>
                    <Text strong>ID Kupca: </Text>
                    {data.userId && data.userId ? data.userId : 'Ne registrovani korisnik'}
                  </p>
                  <p>
                    <Text strong>Ulica: </Text>
                    {data.user && data.user.street ? data.user.street : data.guestUserData.street}
                  </p>
                  <p>
                    <Text strong>Kućni broj: </Text>
                    {data.user && data.user.houseNo ? data.user.houseNo : data.guestUserData.houseNo}
                  </p>
                  <p>
                    <Text strong>Broj stana: </Text>
                    {data.user && data.user.appNo ? data.user.appNo : data.guestUserData.appNo}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {data.user && data.user.city ? data.user.city : data.guestUserData.city}
                  </p>
                  <p>
                    <Text strong>Poštanski broj: </Text>
                    {data.user && data.user.zip ? data.user.zip : data.guestUserData.zip}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {data.user && data.user.phone[0] ? data.user.phone[0] : data.guestUserData.phone[0]}
                  </p>
                  <p>
                    <Text strong>Email: </Text>
                    {data.user && data.user.email ? data.user.email : data.guestUserData.email}
                  </p>
                  <p>
                    <Text strong>Ukupno za naplatu: </Text>
                    {fixedAmount ? fixedAmount : 'No data'}
                  </p>
                </Card>
                <Form.Item label='Status narudžbe' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Osveži
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  size='large'
                  rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={productData && productData}
                  bordered
                  pagination={{
                    defaultPageSize: 20,
                    position: ['topCenter'],
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true,
                  }}
                />
                {/* <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Kreiraj račun
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
