import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const UploadDocument = ({ attachments, setAttachments }) => {
  const currentuser = useContext(UserContext);

  const props = {
    accept: '.pdf',
    onChange({ file, fileList }) {
      setAttachments(fileList);
    },
    headers: {
      Authorization: currentuser.data.token,
    },
    name: 'attachments',
    fileList: attachments,
    onRemove(e) {
      setAttachments(attachments.filter((item) => item.uid !== e.uid));
    },
  };

  console.log(attachments);

  return (
    <Upload {...props} name='file' action={`${SERVER_URL}/upload-file`}>
      <p style={{ paddingLeft: 2, paddingBottom: 5 }}>PDF fajl maksimalno dozvoljen do 5MB</p>
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};
export default UploadDocument;
