import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const BrandsLogoTable = ({ logos, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${Array.isArray(dataIndex) ? dataIndex[0] : dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button type='secondary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getTitle = (key) => {
    switch (key) {
      case 'updatedAt':
        return 'Izmenjeno na datum';

      default:
        console.warn('default');
    }
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: getTitle(item),
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
        ? item === 'title'
          ? [item, user.language ? user.language.selected.code : '']
          : [item, 'name', user.language ? user.language.selected.code : '']
        : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
        ? item === 'title'
          ? [item, user.language ? user.language.selected.code : '']
          : [item, 'name', user.language ? user.language.selected.code : '']
        : item,
    ),
  }));

  columns.push({
    title: 'Akcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {user.data.role.includes('admin') ? (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={editPath + record._id}>
              <EditOutlined
                className='icon-unlock'
                title={`Edit ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EditFilled
                className='icon-lock'
                title={`Edit ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        ) : (
          ''
        )}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeOutlined
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}
        {user.data.role.includes('admin') ? (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='OK'
              cancelText='Cancel'
              cancelButtonProps={{ type: 'secondary' }}
              okButtonProps={{ type: 'primary' }}
            >
              <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
              <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
            </Popconfirm>
          </div>
        ) : (
          ''
        )}
      </div>
    ),
  });

  console.log('aaaaaaaaa', logos);

  return (
    <div className='table-data-okvir'>
      {' '}
      {/* style={{ height: '85vh' }} */}
      <Table
        size='middle'
        bordered
        dataSource={logos}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
      />
    </div>
  );
};

export default BrandsLogoTable;
