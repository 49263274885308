import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import { Tabs, Divider, Input, Checkbox, Select, /* DatePicker, */ Button, Form, Row, Col, TreeSelect } from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import { GalleryModal } from './';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 5;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  text: '',
  status: 'AKTIVNA',
};

const LandingForm = ({ isNew, data, language, onSubmit, SERVER_URL, token, declarations }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [brands, setBrands] = useState();

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  const onChangeAttribute = (index, attrId, name, value) => {
    let prevAttributes = form.getFieldValue('attributes');
    const attr = {
      attributeId: attrId,
      [name]: name === 'values' ? (!Array.isArray(value) ? [value] : value) : value,
    };
    prevAttributes[index] = attr;
    form.setFieldsValue({ attributes: prevAttributes });
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item label='Tekst' name={['text']}>
                  <JoditEditor
                    name={`tekst`}
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Divider type='horizontal' />
                {/* <Form.Item label='Url' name='url'>
                  <Input disabled />
                </Form.Item> */}

                {/* <div>
                  <p style={{ marginTop: '20px' }}>Choose company:</p>
                    <Select
                      allowClear={true}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value) => {
                        localStorage.setItem('selectedCompany', value);
                        setCompany(value);
                      }}
                      style={{ width: '45%', marginBottom: '20px' }}
                      defaultValue={localStorage.getItem('selectedCompany')}
                    >
                      {companiesList.map((item) => (
                        <Option value={item._id} key={item._id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                </div> */}

                <Form.Item
                  label='Status'
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Please select status!',
                    },
                  ]}
                >
                  <Select>
                    {['AKTIVNA', 'NEAKTIVNA'].map((option, index) => (
                      <Select.Option key={`${option}_${index}`} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} data
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LandingForm;
