import React, { useContext, useEffect, useState } from 'react';
// import slugify from 'slugify';
import JoditEditor from 'jodit-react';
import { Divider, Input, Select, /* DatePicker, */ Button, Form, Row, Col } from 'antd';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 10;

const configJodit = {
  style: {
    fontFamily: 'Poppins',
  },
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
    'image',
  ],
};

const formInit = {
  _id: null,
  brand: undefined,
  status: undefined,
  text: undefined,
};

const LandingPageForm = ({
  isNew,
  data,
  // brands,
  onSubmit,
  SERVER_URL,
  token,
}) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);

  const [brands, fetchBrands] = useAxios('', {}, currentuser.data.token, 'get');
  const [products, fetchProducts] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    fetchBrands(`${SERVER_URL}/get-brands`, [brands]);
  }, [fetchBrands]);

  useEffect(() => {
    fetchProducts(`${SERVER_URL}/data`, [products]);
  }, [fetchProducts]);

  const selectCompany = (companyId) => {
    console.log('company id', companyId);
    const foundCompany = brands.data.filter((item) => console.log('item', item));
    setSelectedCompany(foundCompany[0]);
  };

  let initialValues = { ...formInit, ...data };

  console.log('DATATATATA', data);

  // Select ATTRIBUTES
  const onFinish = async (values, isNew) => {
    console.log('values za slanje', values);

    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  // className='employeeField'
                  label='Brend'
                  labelCol={{ span: 6 }}
                  name='brand'
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas izaberite brend!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {brands &&
                      brands.data &&
                      brands.data.length > 0 &&
                      brands.data.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label='URL'
                  name={'pageUrl'}
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas unesite url!',
                    },
                  ]}
                >
                  <Input
                    prefix='https://www.termoklik.rs/reklame/'
                    suffix='.rs'
                    style={{ maxWidth: '600px', paddingLeft: '10px' }}
                  />
                </Form.Item>

                <Form.Item label='Sadržaj stranice' name={'text'}>
                  <JoditEditor
                    name={`text`}
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Divider type='horizontal' />

                <Form.Item
                  // className='employeeField'
                  label='Proizvodi'
                  labelCol={{ span: 6 }}
                  name='products'
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas izaberite brend!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp='children'
                    mode='multiple'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {products &&
                      products.data &&
                      products.data.items &&
                      products.data.items.length > 0 &&
                      products.data.items.map((item, index) => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {item.naziv}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Divider type='horizontal' />

                <Form.Item
                  label='Status'
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Odaberite status!',
                    },
                  ]}
                  style={{ maxWidth: '300px' }}
                >
                  <Select>
                    {['AKTIVNA', 'NEAKTIVNA'].map((option, index) => (
                      <Select.Option key={`${option}_${index}`} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} naziv sekcije
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LandingPageForm;
