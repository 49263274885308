import React, { useEffect, useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import Table from '../../components/tables/LandingPageTable';

const LandingPage = () => {
  const currentuser = useContext(UserContext);
  const [pages, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchData(`${SERVER_URL}/landings`, [pages]);
  }, [fetchData]);

  let columnKeys = ['brand', 'status'];

  let tableData = [];

  console.log('pages', pages);

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/landing-page/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Reklamna strana obrisana.',
        placement: 'bottomRight',
      });
      // history.push('/admin/Pages');
      window.location.reload();
    } catch (error) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte ponovo kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  if (pages && pages?.data?.length !== null) {
    tableData = pages.data.map((item) => {
      return {
        ...item,
      };
    });
  }

  console.log('TABLE DATA', tableData);

  return (
    <div className='table data-table special-data-table'>
      <div style={{ paddingBottom: '20px', paddingTop: '45px', paddingLeft: '5px' }}>
        <Link to='/admin/new-landing-page'>
          <Button type='primary'>Kreiraj novu stranicu</Button>
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        {pages.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!pages.isLoading && pages.data && pages.data.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteHandler}
            columnKeys={columnKeys}
            title='Podaci'
            editPath='/admin/edit-landing-page/'
          />
        )}
        {!pages.isLoading && pages.data && pages.data.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
