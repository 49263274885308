import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/NewsletterTable';
import { SERVER_URL } from '../../config';
import ExportExcel from '../../components/csv/ExportExcel';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'description', 'image', 'user', 'url', 'tag', 'post'];

const Newsletter = () => {
  const currentuser = useContext(UserContext);
  const [newsletter, fetchnewsletter] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchnewsletter(`${SERVER_URL}/newsletter`, []);
  }, [fetchnewsletter]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/newsletter/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (newsletter.data && newsletter.data.items && newsletter.data.items.length > 0) {
    const keys = Object.keys(newsletter.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];

  if (newsletter.data && newsletter.data.items && newsletter.data.items.length > 0) {
    tableData = newsletter.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });

    if (tableData.email === newsletter.email) {
    }
  }

  return (
    <div className='table newsletters'>
      <div style={{ textAlign: 'end', margin: '20px auto' }}>
        <ExportExcel
          csvData={newsletter.data.items}
          fileName={`Emails eksport ` + new Date().toLocaleDateString('sr-RS')} // Ime xlsx fajla
          propsToRemove={[
            '_id',
            'userId',
            'firstName',
            'lastName',
            'description',
            'address',
            'zip',
            'legalType',
            'taxId',
            'role',
            'status',
            'createdAt',
            'updatedAt',
            '__v',
          ]} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
          // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
        />
      </div>

      <div style={{ textAlign: 'center' }}>
        {newsletter.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!newsletter.isLoading && newsletter.data && newsletter.data.items && newsletter.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='newsletter'
            editPath='/admin/edit-newsletter/'
          />
        )}
        {!newsletter.isLoading && newsletter.data && newsletter.data.items && newsletter.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>No Data</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
