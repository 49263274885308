import React, { useEffect, useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/PagesTable';
import Axios from 'axios';

const Page = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');

  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/fronts`, [data]);
  }, [fetchData]);

  console.log('DATA', data);

  let columnKeys = ['name', 'label'];

  let tableData = [];

  if (data && data?.data?.length !== null) {
    tableData = data.data.map((item) => {
      return {
        ...item,
      };
    });
  }

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/front/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Naslovna strana obrisana.',
        placement: 'bottomRight',
      });
      // history.push('/admin/Pages');
      window.location.reload();
    } catch (error) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte ponovo kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  console.log('TABLE DATA', tableData);

  return (
    <div className='table data-table special-data-table'>
      <div style={{ paddingBottom: '20px', paddingTop: '45px', paddingLeft: '5px' }}>
        <Link to='/admin/new-page'>
          <Button type='primary'>Kreiraj novu stranicu</Button>
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteHandler}
            columnKeys={columnKeys}
            title='Pages'
            editPath='/admin/edit-page/'
          />
        )}
        {!data.isLoading && data.data && data.data.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Page;
