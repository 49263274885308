import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/BrandLogosTable';
import { SERVER_URL } from '../../config';

const BrandsLogo = () => {
  const currentuser = useContext(UserContext);
  const [logos, fetchLogos] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  console.log('logos', logos);

  useEffect(() => {
    fetchLogos(`${SERVER_URL}/logos`, []);
  }, [fetchLogos]);

  const deleteBannerHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/logo/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/brands-logos');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys = ['updatedAt'];

  let logosTable = [];
  if (logos.data && logos.data.items && logos.data.items.length > 0) {
    logosTable = logos.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  console.log('logosTable', logosTable);

  return (
    <div className='table data-table'>
      {currentuser.data.role.includes('admin') ? (
        <div className='actions-block'>
          <Link to='/admin/new-logo'>
            <Button type='primary'>Dodaj novi logo</Button>
          </Link>
        </div>
      ) : (
        ''
      )}

      <div style={{ textAlign: 'center' }}>
        {logos.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!logos.isLoading && logos.data && logos.data.items && logos.data.items.length > 0 && (
          <Table
            logos={logosTable}
            deleteHandler={deleteBannerHandler}
            columnKeys={columnKeys}
            title='Logos'
            editPath='/admin/new-logo/'
          />
        )}
        {!logos.isLoading && logos.data && logos.data.items && logos.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema logoa</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandsLogo;
