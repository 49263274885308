import React, { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import { Modal, Row, Col, Tabs, Checkbox, Spin, Upload, Tooltip, Input, message, notification } from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const GalleryModal = ({
  visible,
  formKey,
  limit,
  SERVER_URL,
  token,
  onInsert,
  onCancel,
  form,
  imageType,
  imageHeight,
  imageSavePath,
  imageId,
  imageIndex,
}) => {
  const [images, fetchImages] = useAxios('', null, token, 'get');
  const [gallery, setGallery] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [activeTab, setActiveTab] = useState('GALLERY');
  const [search, setSetSearch] = useState('');

  /**
   * fetch all images from db
   */
  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`, []);
  }, [fetchImages, SERVER_URL]);

  /**
   * If there is previous images in form -> set them to selected state
   */
  useEffect(() => {
    const prevSelected = form.getFieldValue(formKey);
    if (prevSelected?._id) {
      setSelected([prevSelected._id]);
    }
    if (Array.isArray(prevSelected) && prevSelected?.length > 0) {
      setSelected(prevSelected.map((item) => item._id));
    }
  }, [setSelected, form, formKey, limit]);

  /**
   * Sort images so that selected images are first in gallery
   */

  useEffect(() => {
    if (images.data?.length > 0) {
      if (selected.length > 0) {
        const notSelectedImages = images.data.filter((image) => !selected.includes(image._id));
        const selectedImages = selected.map((id) => {
          const img = images.data.find((image) => image._id === id);
          return img;
        });
        setGallery([...selectedImages, ...notSelectedImages]);
      } else {
        setGallery(images.data);
      }
    }
  }, [images]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const selectHandler = (id) => {
    if (limit > 1) {
      if (selected.includes(id)) {
        const removed = selected.filter((item) => item !== id);
        setSelected(removed);
      }
      if (!selected.includes(id) && selected.length < +limit) {
        setSelected([...selected, id]);
      }
      if (selected.length >= +limit) {
        return notification.error({
          message: `Možete odabrati maksimalno ${limit} slika`,
        });
      }
    }
    if (limit === 1) {
      setCurrentSelected(id);
      if (imageIndex >= 0) {
        const newSelected = [...selected];
        if (selected.includes(id)) {
          newSelected.splice(imageIndex, 1);
        } else {
          newSelected.splice(imageIndex, 1, id);
        }
        setSelected(newSelected);
      } else {
        setSelected(!selected.includes(id) ? [id] : []);
      }
    }
  };

  const beforeUpload = (file) => {
    // const isAllowedFormat = ['image/jpeg', 'image/png'].includes(file.type);
    // if (!isAllowedFormat) {
    //   notification.error({
    //     message: 'Možete postaviti samo JPG ili PNG slike!',
    //     placement: 'bottomRight',
    //   });
    //   return Upload.LIST_IGNORE;
    // }
    const isAllowedSize = file.size / 1024 / 1024 < 50;
    if (!isAllowedSize) {
      notification.error({
        message: 'Slika mora biti manja od 5MB!',
        placement: 'bottomRight',
      });
      return false;
    }
    return isAllowedSize;
  };

  const onUpload = async ({ file, fileList }) => {
    if (file.status === 'done') {
      if (file.response && file.response.file) {
        message.success(`${file.response.file.name} slika je uspešno postavljena!`);
        setUploaded([...uploaded, file.response.image]);
        selectHandler(file.response.image._id);
      }
    } else if (file.status === 'error') {
      message.error(`${file.response.file.name} slika nije postavljena.`);
    }
    if (fileList.length > 1 && fileList.every((f) => f.status === 'done')) {
      setUploaded(fileList.map((f) => f.response.image));
    }
  };

  const onOk = () => {
    const selectedImages = selected.map((selectedImg) => {
      const image = [...gallery, ...uploaded].find((image) => image._id === selectedImg);
      return image;
    });
    onInsert(limit === 1 && !(imageIndex >= 0) ? selectedImages[0] : selectedImages);
    onCancel();
  };
  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const objectsPerPage = 10;

  const paginateObjects = (pageNumber) => {
    const startIndex = (pageNumber - 1) * objectsPerPage;
    const endIndex = startIndex + objectsPerPage;
    return gallery.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedObjects = paginateObjects(currentPage);

  return (
    <Modal
      className='gallery-modal'
      centered
      width='100%'
      visible={visible}
      title={<strong>Galerija slika</strong>}
      okText='Ubaci'
      cancelText='Odustani'
      maskClosable={true}
      onOk={onOk}
      onCancel={() => {
        // form.resetFields();
        onCancel();
      }}
    >
      <Tabs
        tabPosition='top'
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        style={{ height: '650px', overflowY: 'auto' }}
      >
        <Tabs.TabPane tab={`GALERIJA (${images?.data?.length || 0})`} key='GALLERY'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Spin spinning={images.isLoading} tip='Učitavam slike...'>
              <Input
                addonAfter={<SearchOutlined style={{ marginLeft: '5px' }} />}
                value={search}
                onChange={(e) => setSetSearch(e.target.value)}
                style={{ width: '250px', marginBottom: '12px', display: 'flex' }}
                placeholder='Pretraga po nazivu slike'
              />
              <div className='gallery-buttons'>
                <button
                  style={{
                    fontWeight: 'bold',
                    width: '110px',
                    marginBottom: '5px',
                    marginRight: '10px',
                    padding: '2px 5px',
                    alignItems: 'center',
                    border: '1px solid black',
                    display: 'flex',
                  }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &lt; &nbsp; Prethodna
                </button>
                <button
                  style={{
                    fontWeight: 'bold',
                    width: '110px',
                    marginBottom: '5px',
                    padding: '5px',
                    alignItems: 'center',
                    border: '1px solid black',
                  }}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Sledeća &nbsp; &gt;
                </button>
              </div>
              <Row gutter={[8, 8]}>
                {paginatedObjects?.length > 0 &&
                  paginatedObjects
                    .filter((image) => image.originalname.toLowerCase().includes(search.toLowerCase()))
                    .map((image, i) => {
                      return (
                        <Col
                          // xs={12} sm={8} md={6} lg={4} xl={3} xxl={2}
                          key={image._id}
                        >
                          <div
                            style={{
                              height: '200px',
                              width: '170px',
                              // gap: '10px',
                              position: 'relative',
                              background: '#ccc',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'end',
                              paddingBottom: '5px',
                              margin: '5px',
                            }}
                          >
                            <img
                              title={image.originalname}
                              src={getUrl(image.url)}
                              alt={image.originalName}
                              style={{ position: 'absolute', height: 'auto', width: '150px', margin: '5px' }}
                            />
                            <Tooltip title={selected.includes(image._id) ? 'Ukloni' : 'Obeleži'}>
                              <Checkbox
                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                checked={selected.includes(image._id)}
                                onChange={() => selectHandler(image._id)}
                                disabled={
                                  imageId
                                    ? image._id !== imageId &&
                                      selected.includes(image._id) &&
                                      currentSelected !== image._id
                                    : false
                                }
                                // disabled={imageIndex ? imageIndex !== i && selected.includes(image._id) : false}
                              />
                            </Tooltip>
                          </div>
                          {/* <p
                            style={{
                              fontSize: '0.725rem',
                              textAlign: 'center',
                            }}
                          >
                            {image.originalname}
                          </p> */}
                        </Col>
                      );
                    })}
              </Row>
            </Spin>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab='POSTAVI' key='UPLOAD' forceRender>
          <Dragger
            multiple={limit > 1}
            maxCount={limit}
            action={`${SERVER_URL}/upload-image`}
            accept='.jpg,.jpeg,.png,.bmp,.svg'
            name='image'
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              // resize: true,
              // resizemob: false,
              height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={beforeUpload}
            onChange={onUpload}
          >
            <p className='ant-upload-drag-icon'>
              <UploadOutlined />
            </p>
            <p className='ant-upload-text'>Klikni ili prevuci sliku do ove zone kako bi je postavili</p>
            <p className='ant-upload-hint'>Maksimalno {limit} slika je moguće postaviti</p>
          </Dragger>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default GalleryModal;
